<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-666"
      ></a>
      <h1 class="mui-title">课程详情</h1>
    </header>
    <!-- 底部 -->
    <nav class="mui-bar mui-bar-tab xq-foot" v-if="detail.has_unlock == 0">
      <div class="mui-tab-item mui-text-left" style="padding-right: 0" href="">
        <span class="color-red f-22"
          >￥{{ detail.order_price }}
          <div class="miaosha">{{ detail.real_price }}</div>
        </span>
        <span class="color-red">已报名：{{ detail.goods.sale_amount }}人</span>
      </div>
      <div
        class="mui-tab-item mui-text-right"
        style="width: 30%"
        v-if="detail.goods.notend_pintuan"
      >
        <span
          style="display: inline-block"
          v-if="timeCode == 1 || timeCode == 3"
        >
          <a
            class="pintuan-but"
            style="float: right; height: 40px"
            @click="payPinTuan"
            >发起拼团<small
              >({{ detail.goods.notend_pintuan.number }}人成团)</small
            >
          </a>
          <a
            class="pintuan-but"
            style="
              display: inline-block;
              height: 40px;
              float: right;
              line-height: 40px;
              background: #eebfd7;
            "
            @click="goPay"
            >单独购买</a
          >
        </span>
        <span v-else>
          <a
            class="pintuan-but"
            style="
              display: inline-block;
              height: 40px;
              float: right;
              line-height: 40px;
              background: #eebfd7;
            "
            >还未到拼团开始时间
          </a>
        </span>
      </div>
      <div class="mui-tab-item mui-text-right" v-else>
        <a class="xq-footBut" @click="goPay">{{
          detail.order_price == 0
            ? "免费报名"
            : detail.order_price == "0.00"
            ? "免费报名"
            : "立即购买"
        }}</a>
      </div>
    </nav>
    <div class="mui-content">
      <div class="bg-fff o_hidden">
        <div class="video">
          <img :src="detail.course_cover[0].image.url" />
        </div>
        <div class="video-tex">
          <h4>{{ detail.goods.name }}</h4>
          <h5 class="color-999">
            购买后有效期：{{ detail.valid_days }}天 {{ detail.period }}课时
          </h5>
          <h5 class="color-main" :style="'color:' + detail.subtitle_color">
            {{ detail.subtitle }}
          </h5>
          <p class="clearfix video-texB">
            <span class="color-999 mui-pull-left"
              >已报名：{{ detail.goods.sale_amount }}人</span
            >
            <span
              class="color-pink mui-pull-right"
              v-if="detail.goods.notend_seckill"
              >距秒杀结束：
              <a-statistic-countdown
                :value="detail.goods.notend_seckill.end_at"
                format="D 天 H 时 m 分 s 秒"
              />
            </span>
            <span
              class="color-pink mui-pull-right"
              v-if="detail.goods.notend_pintuan"
              >距拼团结束：
              <a-statistic-countdown
                :value="detail.goods.notend_pintuan.end_at"
                format="D 天 H 时 m 分 s 秒"
              />
            </span>
          </p>
        </div>
      </div>
      <!-- 拼团 -->
      <div class="bg-fff mt1" v-if="detail.goods.notend_pintuan">
        <PinTuan
          :pintuan_id="detail.goods.notend_pintuan.pintuan_id"
          :goods="detail.goods"
        ></PinTuan>
      </div>
      <div id="slider" class="mui-slider kecheng-tab">
        <div
          id="sliderSegmentedControl"
          class="mui-slider-indicator mui-segmented-control mui-segmented-control-inverted"
        >
          <a class="mui-control-item mui-active" @click="getTab(0)">
            课程列表
          </a>
          <a class="mui-control-item" @click="getTab(1)"> 课题大纲 </a>
          <a class="mui-control-item" @click="getTab(2)"> 学员评价 </a>
          <a id="alertBtn" class="mui-control-item" @click="getTab(3)">
            讲义下载
          </a>
        </div>
        <div class="mui-slider-group">
          <div
            v-if="tabIndex == 0"
            class="mui-slider-item mui-control-content mui-active"
          >
            <div v-html="imgWH(detail.introduce)" class="vhtmlcontent"></div>
          </div>
          <div class="mui-slider-item mui-control-content" v-if="tabIndex == 1">
            <div class="mui-card">
              <ul class="mui-table-view">
                <li
                  :class="
                    index == 0
                      ? 'mui-table-view-cell mui-collapse mui-active'
                      : 'mui-table-view-cell mui-collapse'
                  "
                  v-for="(i, index) in detail.course_chapter"
                >
                  <a class="mui-navigate-right" href="#">{{ i.name }}</a>
                  <div class="mui-collapse-content">
                    <dl
                      class="clearfix dagang pos-rel"
                      v-for="(item, index) in i.course_subchapter"
                      @click="xuexi"
                    >
                      <!-- <dt class="mui-pull-left color-999"></dt> -->
                      <dd>
                        <h5>{{ item.name }}</h5>
                        <h6 class="color-999">
                          {{ item.teacher ? item.teacher.name : "" }}
                        </h6>
                        <strong>
                          <a-progress
                            :percent="item.baijia_video.user_progress"
                            :show-info="false"
                          />
                        </strong>
                        <h6 class="color-yellow">
                          已学习{{ item.baijia_video.user_progress }}%
                        </h6>
                        <!-- <p class="zhuangtai color-blue">
                          <img src="@/assets/img/zhiboz.png" />直播中
                        </p> -->
                      </dd>
                    </dl>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="mui-slider-item mui-control-content" v-if="tabIndex == 2">
            <dl class="pinglun clearfix" v-for="i in commentLists">
              <dt class="mui-pull-left">
                <img class="dis-inb tx" :src="i.user.head_portrait.url" />
              </dt>
              <dd class="mui-pull-right pos-rel">
                <p>
                  {{ i.user.nickname }}
                  <img
                    class="dis-inb jibie"
                    v-if="i.user.now_user_member"
                    :src="
                      i.user.now_user_member.user_member_config_id == 1
                        ? '/img/jibie.png'
                        : i.user.now_user_member.user_member_config_id == 2
                        ? '/img/jibie2.png'
                        : i.user.now_user_member.user_member_config_id == 3
                        ? '/img/jibie3.png'
                        : i.user.now_user_member.user_member_config_id == 4
                        ? '/img/jibie4.png'
                        : i.user.now_user_member.user_member_config_id == 5
                        ? '/img/jibie5.png'
                        : '/img/jibie6.png'
                    "
                  />
                  <!-- <em class="bg-blue zhiding">置顶</em> -->
                </p>
                <p class="color-666">
                  {{ i.content }}
                </p>
                <p class="clearfix">
                  <span v-for="item in i.image">
                    <van-cell is-link @click="showPopup(item.url)"
                      ><img :src="item.url"
                    /></van-cell>
                  </span>
                </p>
                <p
                  class="pinglun-zan color-999"
                  @click="clickLike(i.comment_id, i.is_like)"
                >
                  <img
                    src="@/assets/img/praise.png"
                    v-if="i.is_like == 0"
                  /><img src="@/assets/img/praise-hover.png" v-else />{{
                    i.likes
                  }}
                </p>
              </dd>
            </dl>
          </div>
          <div class="mui-slider-item mui-control-content" v-if="tabIndex == 3">
            <div
              class="jy_list"
              v-for="i in detail.handout"
             
            >
              <img
                class="jy_img"
                :src="i.type == 1 ? '/img/jy.png' : '/img/xueyuan.png'"
                alt=""
                @click="downDetail(i.handout_id)"
              />
              <p @click="downDetail(i.handout_id)">{{ i.name }}</p>
              <!-- <img
                class="jy_icon"
                @click="downLoadFile(i.handout_id)"
                src="@/assets/img/xiazai.png"
                alt=""
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <KeFu></KeFu>
    <InviteBuy
      :share_user_id="share_user_id"
      :share_id="share_id"
      @motalShow="getShow"
      v-show="InviteShow"
    ></InviteBuy>
    <van-popup v-model="imgShow" style="width: 70%">
      <img :src="imgUrl" alt="" />
    </van-popup>
  </div>
</template>
<script>
var jweixin = require("/jweixin-module/index.js");
import storage from "@/utils/storage";
import Mui from "../../assets/js/mui.min.js";
import KeFu from "@/components/kefu";
import PinTuan from "@/components/PinTuan";
import InviteBuy from "@/components/InviteBuy";
import { info, comment_list } from "@/api/course";
import {
  commentLike,
  getShare,
  getSignPackage,
  courseDownload,
} from "@/api/common";
export default {
  name: "courseDetail",
  components: {
    KeFu,
    PinTuan,
    InviteBuy,
  },
  data() {
    return {
      course_id: null,
      detail: {},
      tabIndex: 0,
      commentLists: [],
      imgShow: false,
      imgUrl: null,
      InviteShow: false,
      share_user_id: null,
      share_id: null,
      timeCode: 1,
    };
  },

  methods: {
    loadDetail() {
      let _this = this;
      info({ course_id: _this.course_id }).then((res) => {
        console.log(res, "详情");
        var data = res.result;
        var userInfo = storage.getItem("userInfo");
        let pages =
          "<head><meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no'><style>img{max-width:100% !important;height:auto}</style></head>";
        _this.getSignPackage();
        if (data.goods.notend_pintuan || data.goods.notend_seckill) {
          if (data.goods.notend_seckill) {
            data.order_price = data.goods.notend_seckill.goods.order_price;
            data.real_price = data.goods.real_price;
          }
          if (data.goods.notend_pintuan) {
            data.order_price = data.goods.notend_pintuan.goods.order_price;
            data.real_price = data.goods.real_price;
          }
        } else {
          if (data.goods.vip_price.length > 0) {
            // 如果该课程包有会员价格 则对比会员等级计算会员价 划线价为原价
            if (userInfo) {
              data.goods.vip_price.map(function (s) {
                if (userInfo.now_user_member) {
                  if (
                    s.user_member_config_id ==
                    userInfo.now_user_member.user_member_config_id
                  ) {
                    data.order_price = s.price;
                  }
                } else {
                  data.order_price = data.goods.order_price;
                }
              });
              data.real_price = data.goods.real_price;
            } else {
              data.order_price = data.goods.order_price;
            }
          } else {
            data.order_price = data.goods.order_price;
          }
        }
        _this.detail = data;
        _this.detail.introduce = pages + this.detail.introduce;
        if (_this.detail.goods.notend_pintuan) {
          // 比对时间 当前时间戳小于开始时间 则不予拼团
          console.log(_this.detail.goods.notend_pintuan.start_at, "拼团时间");
          var format = _this.detail.goods.notend_pintuan.start_at.replace(
            /-/g,
            "/"
          );
          var date = new Date(format);
          var myDate = new Date();
          console.log(date, "data");
          console.log(myDate, "myDate");
          _this.timeCode = _this.getTime(myDate, date);
          console.log(_this.getTime(myDate, date), "时间对比");
        }
        if (_this.$route.query.share_user_id) {
          _this.loadShare();
        }
        _this.loadcomment_list();
      });
    },
    getTime(date1, date2) {
      var oDate1 = date1;
      var oDate2 = date2;
      var resultes;
      console.log(oDate1.getTime(), "oDate1");
      console.log(oDate2.getTime(), "oDate2");
      if (parseInt(oDate1.getTime()) > parseInt(oDate2.getTime())) {
        // 第一个大
        resultes = 1;
      } else if (parseInt(oDate1.getTime()) < parseInt(oDate2.getTime())) {
        // 第二个大
        resultes = 2;
      } else if (parseInt(oDate1.getTime()) == parseInt(oDate2.getTime())) {
        // 一样大
        resultes = 3;
      }
      return resultes;
    },
    imgWH(string) {
      //正则替换图片宽高
      if (string) {
        string = string.replace(/ height="\d+"/g, ' height="auto"');
        string = string.replace(/ width="\d+"/g, ' width="100%"');
        return string;
      } else {
        return "";
      }
    },
    getTab(index) {
      this.tabIndex = index;
    },
    loadcomment_list() {
      let _this = this;
      comment_list({ course_id: _this.course_id }).then((res) => {
        console.log(res, "评论");
        _this.commentLists = res.result.items;
      });
    },
    xuexi() {
      this.download();
    },

    downDetail(handout_id) {
      courseDownload({ handout_id: handout_id }).then((res) => {
        if (res.status == 200) {
          window.location.href = res.result;
        } else {
          Mui.alert(res.msg, "温馨提示", function () {});
        }
      });
    },
    downLoadFile(handout_id) {
      let _this = this;
      courseDownload({ handout_id: handout_id }).then((res) => {
        console.log(res, "下载地址");
        if (res.status == 200) {
          if (_this.isWeiXin()) {
            Mui.alert("请点击右上角默认浏览器打开进行下载", "温馨提示", function () {});
          } else {
          // var url = res.result;
          // var url2 = url.replace('http','https');
          // var iframe = document.createElement('iframe');
          // iframe.style.display = 'none';
          // iframe.src = "javascript: '<script>location.href=\"" + url2 + "\"<\/script>'";
          // document.getElementsByTagName('body')[0].appendChild(iframe);
          window.location.href = res.result;
          }
   
        } else {
          Mui.alert(res.msg, "温馨提示", function () {});
        }
      });
    },
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
    download() {
      let _this = this;
      Mui.alert("请到APP端下载课程资料", "温馨提示", function () {
        if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
          var loadDateTime = new Date();
          window.location =
            "shengru://goodId=" +
            _this.detail.goods.relation_id +
            "&type=" +
            _this.detail.goods.type; //schema链接或者universal link
          window.setTimeout(function () {
            //如果没有安装app,便会执行setTimeout跳转下载页
            var timeOutDateTime = new Date();
            if (timeOutDateTime - loadDateTime < 5000) {
              window.location = "https://apps.apple.com/cn/app/id1544691960"; //ios下载地址
            } else {
              window.close();
            }
          }, 500);
        } else if (navigator.userAgent.match(/android/i)) {
          var state = null;
          try {
            window.location =
              "srhx://api.srgongkaow.app/openwith?goodId=" +
              _this.detail.goods.relation_id +
              "&type=" +
              _this.detail.goods.type; //schema链接或者universal link
            window.setTimeout(function () {
              window.location = "https://gongkaow.com/app/app.apk"; //android下载地址
            }, 500);
          } catch (e) {}
        }
      });
    },
    goPay() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: false,
        },
      });
    },
    payPinTuan() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: true,
        },
      });
    },
    // 点赞笔记
    clickLike(comment_id, is_like) {
      let _this = this;
      if (is_like == 0) {
        _this.commentLike(comment_id, 1);
      } else {
        _this.commentLike(comment_id, 0);
      }
    },
    commentLike(id, status) {
      let _this = this;
      commentLike({ comment_id: id, status: status }).then((res) => {
        console.log(res, "点赞");
        if (res.status == 200) {
          _this.loadcomment_list();
        }
      });
    },
    showPopup(url) {
      this.imgShow = true;
      this.imgUrl = url;
    },
    // 获取分享产品id
    loadShare() {
      let _this = this;
      getShare({ goods_id: _this.detail.goods.goods_id }).then((res) => {
        console.log(res, "分享产品id");
        if (res.result) {
          _this.InviteShow = true;
          _this.share_id = res.result.share_id;
        }
      });
    },
      // 拦截器获取当前地址
      GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");

      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符

      return relUrl;
    },
    getShow(e) {
      this.InviteShow = e;
    },
    getSignPackage() {
      let _this = this;
      getSignPackage({
        url: window.location.href,
      }).then((res) => {
        console.log(res, "配置");
        if (res.status == 200) {
          jweixin.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.result.appId, // 必填，公众号的唯一标识
            timestamp: res.result.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.result.nonceStr, // 必填，生成签名的随机串
            signature: res.result.signature, // 必填，签名，见附录1
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "checkJsApi",
            ],
          });
          var data = {
            title: _this.detail.goods.name, // 分享标题
            desc: _this.detail.goods.subtitle, // 分享描述
            link:
              "https://wap.srgongkaow.com/school/courseDetail?course_id=" +
              _this.detail.course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            },
          };
          jweixin.ready(function () {
            jweixin.checkJsApi({
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function (res) {
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
              },
            });
            jweixin.updateAppMessageShareData({
              title: _this.detail.name, // 分享标题
              desc: _this.detail.subtitle, // 分享描述
              link:
                "https://wap.srgongkaow.com/school/courseDetail?course_id=" +
                _this.detail.course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: _this.detail.first_cover.url, // 分享图标
              success: function (res) {
                // 设置成功
              },
              error: function (err) {},
            });
            jweixin.updateTimelineShareData({
              title: _this.detail.name, // 分享标题
              link:
                "https://wap.srgongkaow.com/school/courseDetail?course_id=" +
                _this.detail.course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: _this.detail.first_cover.url, // 分享图标
              success: function (res) {
                // 设置成功
              },
              error: function (err) {},
            });
          });
        }
      });
    },
  },
  created() {
    var url = this.GetUrlRelativePath();
    storage.setItem("LOGIN_SKIP", url);
    if (document.body.clientWidth >= 1200) {
      window.location.href =
        "http://srgongkaow.com/course/info/" + this.$route.query.course_id;
    }
    console.log(window.location.href, "url");
    this.course_id = this.$route.query.course_id;
    this.share_user_id = this.$route.query.share_user_id;
    this.loadDetail();
  },
};
</script>
<style scoped>
>>> .ant-statistic {
  display: inline-block;
  font-size: 14px;
  line-height: 0;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}
.tx {
  border-radius: 50%;
  width: 50px !important;
  height: 50px;
}
.dagang dd {
  width: 100%;
}
.xq-foot {
  padding-bottom: 20px;
}
.jy_list {
  overflow: hidden;
  margin-bottom: 20px;
}
.jy_img {
  float: left;
  width: 70px !important;
}
.jy_list p {
  float: left;
  margin-left: 10px;
  width: 70%;
}
.jy_icon {
  width: 26px !important;
  float: right;
  margin-top: 10px;
}
.mui-bar-tab .mui-tab-item {
  width: 17%;
}
.miaosha {
  font-size: 14px;
  color: #999999;
  font-weight: normal;
  text-decoration: line-through;
  display: inline-block;
  padding: 0;
}
.pintuan-but {
  padding: 0 1rem !important;
}
.vhtmlcontent p > span {
  line-height: inherit !important;
}
</style>