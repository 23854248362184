<template>
  <div>
    <!-- 头部 -->
    <header class="mui-bar mui-bar-nav wx-top">
      <a
        class="mui-action-back mui-icon mui-icon-left-nav mui-pull-left color-666"
      ></a>
      <h1 class="mui-title">课程详情</h1>
    </header>
    <!-- 底部 -->
    <!-- 底部 -->
    <nav
      class="mui-bar mui-bar-tab xq-foot"
      v-if="detail.has_unlock == 0 || detail.has_unlock == undefined"
    >
      <div class="mui-tab-item mui-text-left" href="">
        <span class="color-red f-22">￥{{ detail.goods.order_price }}</span>
        <span class="color-red">已报名：{{ detail.goods.sale_amount }}人</span>
      </div>
      <div
        class="mui-tab-item mui-text-right"
        style="width: 30%"
        v-if="detail.goods.notend_pintuan"
      >
        <a class="pintuan-but" style="float: right" @click="payPinTuan"
          >发起拼团<small
            >({{ detail.goods.notend_pintuan.number }}人成团)</small
          >
        </a>
        <a
          class="pintuan-but"
          style="
            display: inline-block;
            height: 40px;
            float: right;
            line-height: 40px;
            background: #eebfd7;
          "
          @click="goPay"
          >单独购买</a
        >
      </div>
      <div class="mui-tab-item mui-text-right" v-else>
        <a class="xq-footBut" @click="goPay">{{
          detail.order_price == 0
            ? "免费报名"
            : detail.order_price == "0.00"
            ? "免费报名"
            : "立即购买"
        }}</a>
      </div>
    </nav>
    <div class="mui-content">
      <div class="bg-fff o_hidden">
        <div class="video">
          <img :src="detail.first_cover.url" />
        </div>
        <div class="video-tex">
          <h4>{{ detail.goods.name }}</h4>
          <h5 class="color-999">{{ detail.period }}课时</h5>
          <h5 class="color-main" :style="'color:' + detail.subtitle_color">
            {{ detail.subtitle }}
          </h5>
          <h5 class="color-999">
            限售{{ detail.goods.sales_restriction }}人丨已报名：{{
              detail.goods.sale_amount
            }}人
          </h5>
        </div>
      </div>
      <!-- <div class="bg-fff mt1 shenlunBox">
        <div class="swiper-container shenlun">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide mui-text-center"
              v-for="i in detail.relation_goods"
            >
              <a>
                <img :src="i.image.url" />
                <span>{{ i.name }}</span>
              </a>
            </div>
          </div>
        </div>
      </div> -->
      <div class="jiange"></div>
      <div id="slider" class="mui-slider kecheng-tab">
        <div
          id="sliderSegmentedControl"
          class="mui-slider-indicator mui-segmented-control mui-segmented-control-inverted"
        >
          <a class="mui-control-item mui-active" @click="getTab(0)">
            课程介绍
          </a>
          <a class="mui-control-item" @click="getTab(1)"> 课程列表 </a>
        </div>
        <div class="mui-slider-group">
          <div
            v-if="tabIndex == 0"
            class="mui-slider-item mui-control-content mui-active"
          >
            <div v-html="imgWH(detail.introduce)"></div>
          </div>
          <div
            id="item3mobile"
            v-else
            class="mui-slider-item mui-control-content"
          >
            <div
              class="groupList"
              v-for="i in detail.relation_goods"
              @click="openPage(i.relation_id, i.type)"
            >
              <img :src="i.image.url" alt="" />
              <div class="groupList_r">
                <p>{{ i.name }}</p>
                <h4 :style="'color:' + i.relation.subtitle_color">
                  {{ i.relation.subtitle }}
                </h4>
                <div>
                  <span v-for="(item, index) in i.relation.teacher">{{
                    index == i.relation.teacher.length - 1
                      ? item.name
                      : item.name + "丨"
                  }}</span>
                  <h6>￥{{ i.order_price }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <KeFu></KeFu>
    <InviteBuy
      :share_user_id="share_user_id"
      :share_id="share_id"
      @motalShow="getShow"
      v-show="InviteShow"
    ></InviteBuy>
    <van-popup v-model="imgShow" style="width: 70%">
      <img :src="imgUrl" alt="" />
    </van-popup>
  </div>
</template>
<script>
var jweixin = require("/jweixin-module/index.js");
import KeFu from "@/components/kefu";
import Swiper from "../../assets/js/swiper.min.js";
import Mui from "../../assets/js/mui.min.js";
import InviteBuy from "@/components/InviteBuy";
import { coursePackage, commentList } from "@/api/course";
import { getShare, getSignPackage } from "@/api/common";
export default {
  name: "courseDetail",
  components: {
    KeFu,
    InviteBuy,
  },
  data() {
    return {
      course_package_id: null,
      detail: {},
      tabIndex: 0,
      commentLists: [],
      detail: {},
      imgShow: false,
      imgUrl: null,
      InviteShow: false,
      share_user_id: null,
      share_id: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        // 申论滑动
        var swiper = new Swiper(".shenlun", {
          slidesPerView: 3.8,
          spaceBetween: 30,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 800);
    });
  },
  methods: {
    loadDetail() {
      let _this = this;
      coursePackage({ course_package_id: _this.course_package_id }).then(
        (res) => {
          console.log(res, "详情");
          var data = res.result;
          data.start = data.start_at.substring(0, 10);
          data.end = data.end_at.substring(0, 10);
          _this.detail = data;

          if (_this.$route.query.share_user_id) {
            _this.loadShare();
          }
          // _this.loadcomment_list();
        }
      );
    },
    loadcomment_list() {
      let _this = this;
      commentList({ course_package_id: _this.course_package_id }).then(
        (res) => {
          _this.commentLists = res.result.items;
        }
      );
    },
    imgWH(string) {
      //正则替换图片宽高
      if (string) {
        string = string.replace(/ height="\d+"/g, ' height="auto"');
        string = string.replace(/ width="\d+"/g, ' width="100%"');
        return string;
      } else {
        return "";
      }
    },
    getTab(index) {
      this.tabIndex = index;
    },

    xuexi() {
      Mui.alert("请到APP端进行学习", "温馨提示", function () {
        console.log("点击确定");
      });
    },
    download() {
      Mui.alert("请到APP端下载课程资料", "温馨提示", function () {});
    },
    goPay() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: false,
        },
      });
    },
    payPinTuan() {
      this.$router.push({
        path: "/pay",
        query: {
          goods: JSON.stringify(this.detail.goods),
          type: 1,
          isPin: true,
        },
      });
    },
    showPopup(url) {
      this.imgShow = true;
      this.imgUrl = url;
    },
    // 获取分享产品id
    loadShare() {
      let _this = this;
      getShare({ goods_id: _this.detail.goods.goods_id }).then((res) => {
        console.log(res, "分享产品id");
        if (res.result) {
          _this.InviteShow = true;
          _this.share_id = res.result.share_id;
        }
      });
    },
    getSignPackage() {
      let _this = this;
      getSignPackage({
        url: window.location.href,
      }).then((res) => {
        console.log(res, "配置");
        if (res.status == 200) {
          jweixin.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.result.appId, // 必填，公众号的唯一标识
            timestamp: res.result.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.result.nonceStr, // 必填，生成签名的随机串
            signature: res.result.signature, // 必填，签名，见附录1
            jsApiList: [
              "updateAppMessageShareData",
              "updateTimelineShareData",
              "checkJsApi",
            ],
          });
          var data = {
            title: _this.detail.goods.name, // 分享标题
            desc: _this.detail.goods.subtitle, // 分享描述
            link:
              "https://wap.srgongkaow.com/school/courseDetail?course_id=" +
              _this.detail.course_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            success: function () {
              // 用户确认分享后执行的回调函数
            },
            cancel: function () {
              // 用户取消分享后执行的回调函数
            },
          };
          jweixin.ready(function () {
            jweixin.checkJsApi({
              jsApiList: [
                "updateAppMessageShareData",
                "updateTimelineShareData",
              ], // 需要检测的JS接口列表，所有JS接口列表见附录2,
              success: function (res) {
                // 以键值对的形式返回，可用的api值true，不可用为false
                // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
              },
            });
            jweixin.updateAppMessageShareData({
              title: _this.detail.name, // 分享标题
              desc: _this.detail.subtitle, // 分享描述
              link:
                "https://wap.srgongkaow.com/school/courseGroupDetail?course_package_id=" +
                _this.detail.course_package_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: _this.detail.first_cover.url, // 分享图标
              success: function (res) {
                // 设置成功
              },
              error: function (err) {},
            });
            jweixin.updateTimelineShareData({
              title: _this.detail.name, // 分享标题
              link:
                "https://wap.srgongkaow.com/school/courseGroupDetail?course_package_id=" +
                _this.detail.course_package_id, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
              imgUrl: _this.detail.first_cover.url, // 分享图标
              success: function (res) {
                // 设置成功
              },
              error: function (err) {},
            });
          });
        }
      });
    },
    getShow(e) {
      this.InviteShow = e;
    },
    // 拦截器获取当前地址
    GetUrlRelativePath() {
      var url = document.location.toString();
      var arrUrl = url.split("//");
      var start = arrUrl[1].indexOf("/");
      var relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符
      return relUrl;
    },
    openPage(id, type) {
      if (type == 1) {
        this.$router.push({
          path: "/school/courseDetail",
          query: { course_id: id },
        });
      } else if (type == 2) {
        this.$router.push({
          path: "/school/courseGroupDetail",
          query: { course_package_id: id },
        });
      } else if (type == 3) {
        this.$router.push({ path: "/radio/detail", query: { id: id } });
      } else {
        this.$router.push({ path: "/school/detail", query: { id: id } });
      }
    },
  },
  created() {
    var url = this.GetUrlRelativePath();
    storage.setItem("LOGIN_SKIP", url);
    if (document.body.clientWidth >= 1200) {
      window.location.href =
        "http://srgongkaow.com/course-package/info/" +
        this.$route.query.course_package_id;
    }
    this.course_package_id = this.$route.query.course_package_id;
    this.share_user_id = this.$route.query.share_user_id;
    this.loadDetail();
  },
};
</script>
<style scoped>
>>> .ant-statistic {
  display: inline-block;
  font-size: 14px;
  line-height: 0;
}

.color-pink >>> .ant-statistic-content {
  display: inline-block;
}

.color-pink >>> .ant-statistic-content-value {
  font-size: 14px;
  color: #eb98c2;
}
.tx {
  border-radius: 50%;
  width: 50px !important;
  height: 50px;
}
.xq-foot {
  padding-bottom: 20px;
}
.jiange {
  background: #f6f6f6;
  height: 10px;
  width: 100%;
}
.groupList {
  overflow: hidden;
  margin-bottom: 20px;
}
.groupList img {
  float: left;
  width: 30% !important;
}
.groupList_r {
  float: right;
  width: 68%;
}
.groupList_r p {
  margin-bottom: 4px;
}
.groupList_r h4 {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: normal;
}
.groupList_r span {
  color: #777777;
  display: inline-block;
  font-size: 12px;
}
.groupList_r h6 {
  color: red;
  float: right;
  font-size: 14px;
  font-weight: normal;
}
.groupList_r view {
  display: block;
}
.pintuan-but {
  padding: 0 1rem !important;
}
</style>